import React, { useState } from "react";
import { HowItWorksPageData } from "../../models/common";
import {
  CenteredHeader,
  CenteredSectionSmall,
  CenteredSectionWithDots,
  CenteredText,
  Checklist,
  CheckListItemWhite,
  DottedList,
  DottedListItem,
  FullWidthSectionPadded,
  HomeBadge,
  HomeDivider,
  HomeImage,
  ImageContainer,
  PageContents,
} from "../Home/styled";
import AppButtons from "../Home/AppButtons";
import {
  Hero,
  HeroContents,
  HeroBadge,
  HeroTitle,
  HeroDescription,
  DownIcon,
  GreyPlaceholder,
  TutorialCard,
  TutorialHeader,
  TutorialCardOverlay,
  HowItWorksHeader,
  HowItWorksRow,
  WhiteHeader,
  PaddedText,
} from "./styled";
import Header from "../../layout/Header";
import down from "../../images/down.svg";
import { Col, Row } from "antd";
import VideoModal from "../Home/VideoModal";
import { Button } from "../../theme/components";
import PlayIcon from "../../icons/play.svg";
import how_it_works_1 from "../../images/how_it_works_1.png";
import how_it_works_2 from "../../images/how_it_works_2.png";
import how_it_works_3 from "../../images/how_it_works_3.png";
import how_it_works_4 from "../../images/how_it_works_4.png";
import girl_in_app from "../../images/girl_in_app.png";

interface Props {
  data: HowItWorksPageData;
}

const HowItWorksRoot: React.FC<Props> = (props) => {
  const [visible, setVisible] = useState(false);
  const openVideo = () => {
    setVisible(true);
  };
  const closeVideo = () => {
    setVisible(false);
  };
  return (
    <React.Fragment>
      <Hero>
        <Header />
        <HeroContents>
          <HeroBadge>Let's Get Started</HeroBadge>
          <HeroTitle>How It Works</HeroTitle>
          <HeroDescription>
            Eliminate paperwork from your life. Connect your physical assets to
            any accompanying documentation. Share it with anyone, anywhere.
          </HeroDescription>
          <DownIcon src={down} alt="Scroll Down" />
        </HeroContents>
      </Hero>
      <PageContents>
        <CenteredSectionSmall style={{ marginTop: 24 }}>
          <Row type="flex" align="middle" justify="center" gutter={24}>
            <Col md={10} sm={10} xs={24}>
              {/* <ImageContainer>
						<HomeImage src={home_example_4} alt="DeReader - Scan and View" />
						</ImageContainer> */}
              <GreyPlaceholder></GreyPlaceholder>
            </Col>
            <Col md={14} sm={14} xs={24}>
              <HomeBadge>Our Story</HomeBadge>
              <h2>A Message from our founders: Jim Williams and Lisa Balash</h2>
              <p>
                My business partner Lisa and I come from an oil and gas
                background with a combined experience of over 50 years. We
                started as welders and worked our way up through the inspection
                and quality aspects of our careers. We have both seen and
                experienced the difficulties of trying to complete a project,
                not having access to the information or having to purchase a new
                asset because the one you have on-site does not have paperwork.
              </p>
              <p>
                We have witnessed commissioning not being able to work as they
                are missing data sheets or manuals. We have seen companies have
                to spend extra costs and time to rebuild spools and purchase new
                assets just because they can not find the paperwork that is
                required by regulations before it can be installed.
              </p>
              <p>
                We figured there had to be a better way to not only save time
                but save costs due to down time while waiting to receive the
                information.
              </p>
            </Col>
          </Row>
        </CenteredSectionSmall>
        <CenteredSectionSmall>
          <Row type="flex" align="top" justify="center" gutter={8}>
            <Col md={8} sm={8} xs={24}>
              <HomeBadge>Our Process</HomeBadge>
              <h2 style={{ fontSize: 28 }}>Store and retrieve public data</h2>
            </Col>
            <Col md={16} sm={16} xs={24}>
              <PaddedText>
                This tedious frustrating process helped us develop our app. We
                discovered a huge need for a service that can store and retrieve
                public data. If you have a product or asset people want to
                retrieve information for, our app is for you.
              </PaddedText>
              <AppButtons justify="start" style={{ maxHeight: 40 }} />
            </Col>
          </Row>
        </CenteredSectionSmall>
        <HomeDivider />
        <CenteredSectionSmall>
          <VideoModal
            url="https://www.youtube.com/watch?v=uFhvqeyNWZ0"
            visible={visible}
            onClose={closeVideo}
          />
          <TutorialCard>
            <TutorialCardOverlay />
            <TutorialHeader>DeReader Tutorial</TutorialHeader>
            <Button
              color="primary"
              size="default"
              onClick={openVideo}
              style={{ marginBottom: 48, zIndex: 1 }}
            >
              <PlayIcon /> Watch the Video
            </Button>
          </TutorialCard>
        </CenteredSectionSmall>
        <CenteredSectionSmall>
          <HomeBadge>Examples Of Use</HomeBadge>
          <CenteredHeader>
            DeReader is not limited to office and workplace use
          </CenteredHeader>
          <CenteredText>
            Our app can be implemented in all facets of your day-to-day life.
          </CenteredText>
          <HowItWorksRow
            gutter={48}
            type="flex"
            align="middle"
            justify="center"
            style={{ marginTop: 64 }}
          >
            <Col md={10} sm={10} xs={24}>
              <ImageContainer>
                <HomeImage
                  src={how_it_works_1}
                  alt="DeReader - Use DeReader QR codes"
                />
              </ImageContainer>
            </Col>
            <Col md={14} sm={14} xs={24}>
              <HomeBadge>Organize Information</HomeBadge>
              <HowItWorksHeader>
                Use DeReader QR codes for projects and group work in the office
                or at school
              </HowItWorksHeader>
              <p>
                DeReader can store anything you need to track information and
                share with colleagues, teachers and students. Multiple uses, for
                all ages with no geographical barriers
              </p>
            </Col>
          </HowItWorksRow>

          <HowItWorksRow
            gutter={48}
            type="flex"
            align="middle"
            justify="center"
          >
            <Col md={10} sm={10} xs={24}>
              <ImageContainer>
                <HomeImage
                  src={how_it_works_2}
                  alt="DeReader - Track your information"
                />
              </ImageContainer>
            </Col>
            <Col md={14} sm={14} xs={24}>
              <HomeBadge>Household Use</HomeBadge>
              <HowItWorksHeader>
                Track your receipts and other information
              </HowItWorksHeader>
              <p>
                Track all of your receipts and other information in your home
                with DeReader.
              </p>
              <DottedList>
                <DottedListItem>Insurance</DottedListItem>
                <DottedListItem>
                  Renovations (Paint colours, tools, measurements)
                </DottedListItem>
              </DottedList>
              <p>
                You can collect and store all the important assets for your home
                in one central location. So that even if you lose all the
                physical receipts, you will still have the digital copies in
                your DeReader account. Keep important information safe.
              </p>
            </Col>
          </HowItWorksRow>

          <HowItWorksRow
            gutter={48}
            type="flex"
            align="middle"
            justify="center"
          >
            <Col md={10} sm={10} xs={24}>
              <ImageContainer>
                <HomeImage
                  src={how_it_works_3}
                  alt="DeReader - Track your car"
                />
              </ImageContainer>
            </Col>
            <Col md={14} sm={14} xs={24}>
              <HomeBadge>Automotive</HomeBadge>
              <HowItWorksHeader>
                Track your car receipts and work done on your vehicle
              </HowItWorksHeader>
              <p>
                Track all of your receipts and other information in your home
                with DeReader.
              </p>
              <DottedList>
                <DottedListItem>
                  Online - Attach DeReader QR codes on your posting, anyone
                  interested can quickly scan it and verify the vehicle.
                </DottedListItem>
                <DottedListItem>
                  On the car - Buyers can scan it on your car and see all the
                  relevant information you need them to see.
                </DottedListItem>
              </DottedList>
              <p>Share codes digitally or physically</p>
            </Col>
          </HowItWorksRow>

          <HowItWorksRow gutter={48} type="flex" align="top" justify="center">
            <Col md={10} sm={10} xs={24}>
              <ImageContainer style={{ maxHeight: 700 }}>
                <HomeImage
                  style={{ maxHeight: 700 }}
                  src={how_it_works_4}
                  alt="DeReader - You can create specific assets"
                />
              </ImageContainer>
            </Col>
            <Col md={14} sm={14} xs={24}>
              <HomeBadge>Business</HomeBadge>
              <HowItWorksHeader>
                You can create specific assets for your business
              </HowItWorksHeader>
              <p>Whether it be company cars, equipment or inventory.</p>
              <p>
                The need to broadcast information to select people who have
                access to the DeReader QR code can be useful for many business
                cases:
              </p>
              <DottedList>
                <DottedListItem>Inventory management</DottedListItem>
                <DottedListItem>Warehouses</DottedListItem>
                <DottedListItem>Company cars and equipment</DottedListItem>
                <DottedListItem>Advertisement</DottedListItem>
                <DottedListItem>Storefront signs</DottedListItem>
                <DottedListItem>Product information</DottedListItem>
              </DottedList>
              <p>
                When testing equipment, no need to look for calibration
                certification paperwork. Now the certifications are with your
                equipment in real-time. Just scan and see.
              </p>
              <p>
                DeReader allows you to edit information within a tag, so if you
                were to sell an asset or acquire a new one there won’t be any
                added costs. Our tags are fully transferable and once the new
                owner’s encryption is updated, they have full access to change
                the information as their own. Save your business time and money
              </p>
            </Col>
          </HowItWorksRow>
        </CenteredSectionSmall>
      </PageContents>
      <FullWidthSectionPadded>
        <div style={{ maxWidth: 960 }}>
          <Row gutter={24} type="flex" align="top" justify="center">
            <Col md={12} sm={24} xs={24}>
              <ImageContainer>
                <HomeImage src={girl_in_app} alt="DeReader - Benefits" />
              </ImageContainer>
            </Col>
            <Col md={12} sm={24} xs={24}>
              <WhiteHeader>Benefits</WhiteHeader>
              <Checklist>
                <CheckListItemWhite>Save time and money</CheckListItemWhite>
                <CheckListItemWhite>
                  Environmentally friendly: Eliminate the need for paperwork
                  with the DeReader App
                </CheckListItemWhite>
                <CheckListItemWhite>
                  Multiple uses (work or personal) This app will benefit you at
                  work or at home
                </CheckListItemWhite>
                <CheckListItemWhite>
                  Keeps your information safe and with you at all times
                </CheckListItemWhite>
                <CheckListItemWhite>
                  Easy for anyone to use, from beginners to tech geeks!
                </CheckListItemWhite>
                <CheckListItemWhite>
                  Share information virtually to anyone in person or online
                </CheckListItemWhite>
                <CheckListItemWhite>
                  Feel reassured knowing your information is stored and backed
                  up
                </CheckListItemWhite>
                <CheckListItemWhite>
                  With a traditional QR code, you won’t be able to make edits to
                  the information stored within the code. When you do, you’ll
                  have to issue a new QR code. DeReader eliminates this
                  inconvenience. The DeReader code never changes but you can
                  edit and update new details using your DeReader profile.
                </CheckListItemWhite>
              </Checklist>
            </Col>
          </Row>
        </div>
      </FullWidthSectionPadded>
      <CenteredSectionWithDots
        style={{
          paddingBottom: 112,
          backgroundPosition: "90% 95%",
          marginTop: 48,
        }}
      >
        <HomeBadge>Download The App</HomeBadge>
        <CenteredHeader style={{ maxWidth: 768, marginBottom: 0 }}>
          Everything you need to manage your assets, in the palm of your hands.
        </CenteredHeader>
        <AppButtons />
      </CenteredSectionWithDots>
    </React.Fragment>
  );
};

export default HowItWorksRoot;
