import React from 'react';
import Layout from '../layout/Layout';
import HowItWorksRoot from '../views//HowItWorks/HowItWorksRoot';
import { graphql } from 'gatsby';
import { Seo } from '../components/Seo'

const HowItWorksPage = (props) => (
	<Layout>
		<Seo
			metaDescription={"Eliminate paperwork from your life. Connect your physical assets to any accompanying documentation. Share it with anyone, anywhere."}
			title={"How It Works"}
			slug={'/how-it-works'}
		/>
		<HowItWorksRoot data={props.data} />
	</Layout>
);

export default HowItWorksPage;

export const query = graphql`
	query HowItWorksPage {
		howItWorksPage: contentfulHowItWorksPage {
			title
		}
	}
`;
